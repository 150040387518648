/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, Context, Provider, Consumer } from 'react';
import ActionBaseProps from '../components/form/action-types/ActionBaseProps';
import { FormAction, FormSection, NumberingLevel } from '../models/Form';
import { FormBuilderPlaceholder } from '../components/form-builder/FormBuilderTypes';

export type AdHocOnlyFields = {
  fieldId?: string;
  numberingLevel?: NumberingLevel;
  nextNumbers?: {
    current: string;
    below: string;
  };
};

export type CurrentFormAction = FormAction & AdHocOnlyFields;

export type FormActionContextType<T> = {
  currentSection: FormSection;
  currentAction: CurrentFormAction;
  riskLevel: number;
  actionValid: boolean;
  readOnly: boolean;
  busySavingAction: boolean;
  onAnswer: (answer: T) => Promise<void>;
  onValid: (valid?: boolean) => void;
  createPlaceholder: (placeholder: FormBuilderPlaceholder) => void;
  deletePlaceholder: (value: string) => void;
};

type ExtractAnswerType<T> = T extends ActionBaseProps<infer TAnswer, any> ? TAnswer | null : never;

const FormActionContextInternal = <T,>(): Context<FormActionContextType<T>> =>
  createContext<FormActionContextType<T>>({
    currentSection: null!,
    currentAction: null!,
    riskLevel: 0,
    actionValid: false,
    readOnly: true,
    busySavingAction: false,
    onAnswer: () => {
      throw new Error('Cannot use outside FormActionContext');
    },
    onValid: () => {
      throw new Error('Cannot use outside FormActionContext');
    },
    createPlaceholder: () => {
      throw new Error('Cannot use outside FormActionContext');
    },
    deletePlaceholder: () => {
      throw new Error('Cannot use outside FormActionContext');
    },
  });

export const FormActionContext = ((): {
  context: Context<FormActionContextType<unknown>>;
  Provider: Provider<FormActionContextType<unknown>>;
  Consumer: Consumer<FormActionContextType<unknown>>;
} => {
  const context = FormActionContextInternal();
  return {
    context,
    Provider: context.Provider,
    Consumer: context.Consumer,
  };
})();

export const useFormAction = <TAnswer,>(props?: ActionBaseProps<TAnswer, any>): FormActionContextType<ExtractAnswerType<typeof props>> =>
  useContext(FormActionContext.context);
